<template>
    <div class="wrap">
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="订单号">
                    <el-input v-model="searchForm.order_no"></el-input>
                </el-form-item>
                <el-form-item label="活动名称">
                    <el-input v-model="searchForm.activity_name"></el-input>
                </el-form-item>
                <el-form-item label="买家姓名">
                    <el-input v-model="searchForm.buyer_name"></el-input>
                </el-form-item>
                <el-form-item label="买家电话">
                    <el-input v-model="searchForm.buyer_mobile"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button plain @click="reset()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column label="订单号" prop="order_no" />
            <el-table-column label="活动标题" prop="activity_title" />
            <el-table-column label="活动封面">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="scope.row.activity_image" :preview-src-list="[scope.row.activity_image]" />
                </template>
            </el-table-column>
            <el-table-column label="买家" prop="buyer_name" />
            <el-table-column label="买家电话" prop="buyer_mobile" />
            <el-table-column label="支付金额" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.pay_money}` }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="支付状态" align="center">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" plain v-if="scope.row.pay_status === 2">已支付</el-button>
                    <el-button type="danger" size="mini" plain v-else-if="scope.row.pay_status === 1">未支付</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="订单状态" align="center">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" plain v-if="scope.row.order_status === 2">已结算</el-button>
                    <el-button type="danger" size="mini" plain v-else-if="scope.row.order_status === 1">未结算</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column label="订单创建时间">
                <template slot-scope="scope">
                    {{ $util.date.format(scope.row.created_at, 'yyyy-MM-dd hh:mm:ss') }}
                </template>
            </el-table-column> -->
            <el-table-column prop="operate" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="toDetail(scope.row.id)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-size="searchForm.page_size"
                layout="prev, pager, next, total, jumper"
                :total="searchForm.total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchForm: {
                order_no: '',
                activity_name: '',
                buyer_name: '',
                buyer_mobile: '',
                page: 1,
                page_size: 1,
                total: 0,
            },
            loading: false,
            tableData: [],
        };
    },
    created: function() {
        this.search();
    },
    methods: {
        pageChange: function(page) {
            this.searchForm.page = page;
            this.search();
        },
        search: function() {
            this.loading = true;
            let searchForm = this.$util.emptyToUndefined(this.searchForm);
            this.axios.post('/api/merchant/CarActivity/orderList', searchForm).then((res) => {
                this.searchForm.total = res.data.total;
                this.tableData = res.data.data;
                this.searchForm.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        reset: function() {
            this.searchForm.order_no = '';
            this.searchForm.activity_name = '';
            this.searchForm.buyer_name = '';
            this.searchForm.buyer_mobile = '';
            this.search();
        },
        toDetail: function(id) {
            this.$router.push(`/activity/kuqa/order/detail/${id}`);
        },
    },
};
</script>
<style scoped lang="scss"></style>
